"use client";

import { useState } from "react";
import { ImSpinner8 } from "react-icons/im";
import { RiExpandLeftFill } from "react-icons/ri";
import ErrorIdsToggleButton from "./errorIdsToggleButton";
import { OrganizationCombobox } from "./organization-combobox";
import ReplayAnalysisToggleButton from "./replayAnalysisToggleButton";
import ReplayIdsToggleButton from "./replaysIdsToggleButton";
import SignatureIdsToggleButton from "./signatureIdsToggleButton";
const InternalToolbar = ({
  orgs,
  viewingAsName
}: {
  orgs: any[];
  viewingAsName: string;
}) => {
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  return <div className={`fixed bottom-5 left-5 bg-gray-800 text-white py-2 px-4 rounded-md shadow-lg z-50 ${isCollapsed ? "w-auto" : "min-w-1/4 w-auto"} h-16 flex items-center justify-center gap-4`} data-sentry-component="InternalToolbar" data-sentry-source-file="internal-toolbar.tsx">
      <button onClick={() => setIsCollapsed(!isCollapsed)} className="hover:text-gray-300 transition-colors">
        <RiExpandLeftFill className={`h-5 w-5 transform transition-transform ${isCollapsed ? "rotate-180" : ""}`} data-sentry-element="RiExpandLeftFill" data-sentry-source-file="internal-toolbar.tsx" />
      </button>

      {!isCollapsed && <>
          <ReplayIdsToggleButton />
          <SignatureIdsToggleButton />
          <ErrorIdsToggleButton />
          <ReplayAnalysisToggleButton />
        </>}

      <div className="flex items-center gap-2">
        <OrganizationCombobox orgs={orgs} viewingAsName={viewingAsName} onOrgChange={({
        customerId,
        orgId
      }) => {
        setIsLoading(true);
        fetch("/api/mock", {
          method: "POST",
          body: JSON.stringify({
            newCustomerId: customerId,
            orgId
          })
        }).then(() => {
          window.location.reload();
        }).catch(() => {
          setIsLoading(false);
        });
      }} data-sentry-element="OrganizationCombobox" data-sentry-source-file="internal-toolbar.tsx" />
        {isLoading && <ImSpinner8 className="animate-spin h-4 w-4" />}
      </div>
    </div>;
};
export default InternalToolbar;