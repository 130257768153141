"use client";

import { useEffect, useState } from "react";
import { getTotalSessionCount } from "@/app/_utils/session-count";
function AnimatedCounter() {
  const [count, setCount] = useState<number>(0);
  const [isLoading, setIsLoading] = useState(true);
  const [isVisible, setIsVisible] = useState(false);
  useEffect(() => {
    const fetchCount = async () => {
      try {
        const count = await getTotalSessionCount();
        setCount(count);
        // Small delay before showing to ensure smooth animation
        setTimeout(() => setIsVisible(true), 100);
      } catch (error) {
        console.error("Error fetching session count:", error);
      } finally {
        setIsLoading(false);
      }
    };

    // Initial fetch
    fetchCount();

    // Set up polling every 10 seconds
    const timer = setInterval(fetchCount, 10000);
    return () => clearInterval(timer);
  }, []);
  if (isLoading) {
    return null;
  }
  return <div className={`
        flex items-center gap-1.5 mt-0.5
        transition-all duration-700 ease-in-out
        ${isVisible ? "opacity-100 max-h-8" : "opacity-0 max-h-0 overflow-hidden"}
      `} data-sentry-component="AnimatedCounter" data-sentry-source-file="company-title.tsx">
      <div className="w-1.5 h-1.5 rounded-full bg-gradient-to-r from-teal-400 to-emerald-400 animate-pulse" />
      <span className="text-sm text-neutral-500 dark:text-neutral-400">{(count || 0).toLocaleString()} sessions analyzed</span>
    </div>;
}
export function CompanyTitle() {
  return <div className="flex flex-col" data-sentry-component="CompanyTitle" data-sentry-source-file="company-title.tsx">
      <div className="flex items-center">
        <h2 className="text-2xl font-bold tracking-tight">Decipher AI</h2>
      </div>
      <AnimatedCounter data-sentry-element="AnimatedCounter" data-sentry-source-file="company-title.tsx" />
    </div>;
}