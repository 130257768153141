import { type ClassValue, clsx } from "clsx"
import { twMerge } from "tailwind-merge"

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function baseUrl(): string {
  return process.env.VERCEL_ENV === "production"
    ? "https://app.getdecipher.com"
    : "https://localhost:3000"
}
