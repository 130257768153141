import { IconAmpersand, IconArrowDown, IconArrowRight, IconBackground, IconBrain, IconBulb, IconClick, IconHandClick, IconHelp, IconNotesOff, IconSearch, IconSettings, IconTarget, IconTelescope, IconUserStar } from "@tabler/icons-react";
import { PiArrowSquareOut, PiBookOpen, PiBuildings, PiCreditCard, PiFileText, PiLineSegments, PiListDashes, PiMonitorPlay, PiMoonStars, PiSiren, PiSparkle, PiSun } from "react-icons/pi";
import { VscBracketError } from "react-icons/vsc";
export const PageIcons = {
  Issues: PiListDashes,
  AllReplays: PiMonitorPlay,
  UserBehavior: PiLineSegments,
  Errors: VscBracketError,
  Reports: PiFileText,
  Insights: PiSparkle,
  Alerts: PiSiren,
  OrganizationSettings: PiBuildings,
  Billing: PiCreditCard,
  Docs: PiBookOpen
};
export const ThemeIcons = {
  DarkMode: PiMoonStars,
  LightMode: PiSun
};

// These aren't used outside admin, except for "ExternalLink"
export const MiscIcons = {
  ExternalLink: PiArrowSquareOut,
  Settings: IconSettings,
  ArrowDown: IconArrowDown,
  ArrowRight: IconArrowRight,
  Search: IconSearch,
  Lightbulb: IconBulb,
  NoResults: IconNotesOff,
  Telescope: IconTelescope,
  HelpCircle: IconHelp
};
export const EnrichedSessionIcons = {
  FeatureUsed: IconTarget,
  ClickTarget: IconClick,
  UserIntention: IconUserStar,
  ContextOfInteraction: IconBackground,
  UserAction: IconHandClick,
  InteractionAnalysis: IconBrain,
  ANDOperator: IconAmpersand
};