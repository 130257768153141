"use client";

import { Button } from "@/components/ui/button";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList } from "@/components/ui/command";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
import { cn } from "@/lib/utils";
import { Check, ChevronsUpDown } from "lucide-react";
import * as React from "react";
interface Organization {
  name: string;
  id: number;
  customer_id: string;
}
interface OrganizationComboboxProps {
  orgs: Organization[];
  viewingAsName: string;
  // Update the callback signature to include both customerId and orgId
  onOrgChange?: (data: {
    customerId: string;
    orgId: number;
  }) => void;
}
export function OrganizationCombobox({
  orgs = [],
  viewingAsName,
  onOrgChange
}: OrganizationComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState(viewingAsName);

  // Find the Decipher organization
  const decipherOrg = orgs.find(org => org.name === "getdecipher.com");
  return <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-component="OrganizationCombobox" data-sentry-source-file="organization-combobox.tsx">
      <PopoverTrigger asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="organization-combobox.tsx">
        <Button variant="ghost" size="sm" role="combobox" aria-expanded={open} className="flex items-center gap-2 text-white hover:text-white hover:bg-gray-700 border border-white rounded-sm" data-sentry-element="Button" data-sentry-source-file="organization-combobox.tsx">
          {value || "Select organization..."}
          <ChevronsUpDown className="h-4 w-4 opacity-50" data-sentry-element="ChevronsUpDown" data-sentry-source-file="organization-combobox.tsx" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0 bg-gray-800 border border-white text-xs" data-sentry-element="PopoverContent" data-sentry-source-file="organization-combobox.tsx">
        <Command className="bg-gray-800" data-sentry-element="Command" data-sentry-source-file="organization-combobox.tsx">
          <CommandInput placeholder="Search organization..." className="text-white" data-sentry-element="CommandInput" data-sentry-source-file="organization-combobox.tsx" />
          <CommandList data-sentry-element="CommandList" data-sentry-source-file="organization-combobox.tsx">
            <CommandEmpty className="text-white" data-sentry-element="CommandEmpty" data-sentry-source-file="organization-combobox.tsx">No organization found.</CommandEmpty>
            <CommandGroup data-sentry-element="CommandGroup" data-sentry-source-file="organization-combobox.tsx">
              {orgs.map(org => <CommandItem key={org.id} value={org.name} className="text-white hover:bg-gray-700" onSelect={currentValue => {
              setValue(currentValue);
              setOpen(false);
              if (onOrgChange) {
                // Pass both the customer_id and the org id
                onOrgChange({
                  customerId: org.customer_id,
                  orgId: org.id
                });
              }
            }}>
                  <Check className={cn("mr-2 h-4 w-4", value === org.name ? "opacity-100" : "opacity-0")} />
                  {org.name}
                </CommandItem>)}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
      {decipherOrg && value !== decipherOrg.name && <Button variant="ghost" size="sm" className="ml-2 px-2  bg-blue-600 text-white rounded" onClick={() => {
      setValue(decipherOrg.name);
      if (onOrgChange) {
        onOrgChange({
          customerId: decipherOrg.customer_id,
          orgId: decipherOrg.id
        });
      }
    }}>
          Reset to Decipher
        </Button>}
    </Popover>;
}